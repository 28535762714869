<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-primary has-text-weight-bold is-size-4">
        {{ $t('Edit Sender') }}
      </h3>
    </div>

    <a-form-item class="column is-12" :label="$t('Sender email address')" name="email">
      <a-input v-model:value="form.email" :size="size" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Sender email name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit Sender": "Edit Sender"
  }
}
</i18n>

<script>
export default {
  name: 'SenderForm',
  props: {
    value: {
      type: Object,
      default: null,
    },
    property: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      size: 'large',
      loading: false,
      form: {
        email: undefined,
        name: undefined,
      },
      rules: {
        email: [
          {
            type: 'email',
            required: true,
            message: this.$t('Email is required'),
            trigger: ['change', 'blur'],
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('Name is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          const keys = Object.keys(nv);
          keys.forEach((key) => {
            this.form[key] = nv[key];
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.$store.dispatch('guest-email/updateSender', {
        propertyId: this.property.newPropertyCode,
        ...this.form,
      })
        .finally(() => {
          this.loading = false;
          this.$emit('close');
        });
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
