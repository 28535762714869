<template>
  <a-form ref="form" :model="form" :rules="modifiedRules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12 mb-4" :label="$t('Copy from')" name="copy">
      <InputRoomFromExisting
        v-model:value="copyOfId"
        :property-id="$route.params.id"
        :size="size"
        @change="handleRoomCopy"
      />
    </a-form-item>
    <a-form-item
      v-if="property.anshinPropertyId"
      class="column is-12"
      :label="$t('Anshin Room')"
      name="anshinRoomId"
    >
      <a-select v-model:value="form.anshinRoomId" :size="size">
        <a-select-option
          v-for="item in anshinRooms"
          :key="item.accommodationsId"
          :value="item.accommodationsId"
        >
          {{ item.displayName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Code')" name="code">
      <a-input v-model:value="form.code" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      v-if="property.useRoomType"
      class="column is-12"
      :label="$t('Room Type')"
      name="roomTypeId"
    >
      <InputRoomType
        v-model:value="form.roomTypeId"
        :property-id="property.newPropertyCode"
        @change="handleRoomTypeChange"
      />
    </a-form-item>
    <a-form-item
      v-if="property.useRoomType"
      class="column is-12"
      :label="$t('Room Number')"
      name="roomNumber"
    >
      <a-input
        v-model:value="form.roomNumber"
        :size="size"
        type="number"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Rakuten Travel ID')" name="rtRoomId">
      <a-select
        v-model:value="form.rtRoomId"
        :size="size"
        mode="tags"
      >
        <a-select-option
          v-for="id in form.rtRoomId"
          :key="id"
          :name="id"
          :value="id"
        >
          {{ id }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Facilities')" name="facilities">
      <Modal>
        <template #default="{ hide }">
          <h3 class="has-text-black has-text-weight-bold is-size-4">
            {{ $t('Facility') }}
          </h3>
          <InputFacility
            v-model:value="form.facilities"
            :property-id="property.newPropertyCode"
          />
          <div class="has-text-right mt-3">
            <a-button
              type="primary"
              @click="() => { hide(); }"
            >
              {{ $t('Done') }}
            </a-button>
          </div>
        </template>
        <template #handler="{ show }">
          <a-button type="primary" @click.prevent="show">
            {{ $t('Add') }}
          </a-button>
          <div>
            <a-tag
              v-for="facility in form.facilities"
              :key="facility.id"
              :name="facility.name"
              closable
              @close="removeFacility(facility.id)"
            >
              {{ facility.name }}
            </a-tag>
          </div>
        </template>
      </Modal>
    </a-form-item>
    <div class="column is-12">
      <div class="form-title">
        {{ $t('Capacity') }}
      </div>
    </div>
    <a-form-item class="column is-6" :label="$t('Capacity')" name="capacity">
      <a-input-number v-model:value="form.capacity" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Total Beds')" name="totalBeds">
      <a-input-number v-model:value="form.totalBeds" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Single Beds')" name="singleBeds">
      <a-input-number v-model:value="form.singleBeds" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Semi-Double Beds')" name="semiDoubleBeds">
      <a-input-number
        v-model:value="form.semiDoubleBeds"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Double Beds')" name="doubleBeds">
      <a-input-number v-model:value="form.doubleBeds" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Futons')" name="futons">
      <a-input-number v-model:value="form.futons" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Bedrooms')" name="bedrooms">
      <a-input-number v-model:value="form.bedrooms" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <div class="column is-12">
      <div class="form-title">
        {{ $t('Room Plan') }}
      </div>
    </div>
    <a-form-item class="column is-12" :label="$t('Room Plan Diagram')" name="diagram">
      <img
        v-if="typeof form.roomPlan.diagram === 'string'"
        :src="$filters.imageUrl(form.roomPlan.diagram)"
        width="100"
        height="100"
      >
      <a-upload
        v-show="!form.roomPlan.diagram || typeof form.roomPlan.diagram === 'object'"
        v-model:default-file-list="fileList"
        name="images"
        list-type="picture-card"
        accept="image/png,image/jpeg"
        :multiple="false"
        :action="uploadUrl"
        :headers="apiHeaders"
        @change="handleImageChange"
      >
        <a-button type="primary" :size="size">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Breaker location')"
      name="roomPlan.breakerlocation"
    >
      <a-input v-model:value="form.roomPlan.breakerlocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Gas Meter Type Number')"
      name="roomPlan.gasMeterTypeNumber"
    >
      <a-input v-model:value="form.roomPlan.gasMeterTypeNumber" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Gas Meter Location')"
      name="roomPlan.gasMeterLocation"
    >
      <a-input v-model:value="form.roomPlan.gasMeterLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('IH Stove Type')" name="roomPlan.stoveType">
      <a-input v-model:value="form.roomPlan.stoveType" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Wifi Router Location')"
      name="roomPlan.wifiRouterLocation"
    >
      <a-input v-model:value="form.roomPlan.wifiRouterLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Wifi Accesspoint Location')"
      name="roomPlan.wifiAccesspointLocation"
    >
      <a-input v-model:value="form.roomPlan.wifiAccesspointLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Key 1')" name="roomPlan.wifiKey1">
      <a-input v-model:value="form.roomPlan.wifiKey1" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Password 1')" name="roomPlan.wifiPassword1">
      <a-input v-model:value="form.roomPlan.wifiPassword1" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Key 2')" name="roomPlan.wifiKey2">
      <a-input v-model:value="form.roomPlan.wifiKey2" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Wifi Password 2')" name="roomPlan.wifiPassword2">
      <a-input v-model:value="form.roomPlan.wifiPassword2" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Boiler Control Panel Location')"
      name="roomPlan.boilerControlPanelLocation"
    >
      <a-input v-model:value="form.roomPlan.boilerControlPanelLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Square Meter')" name="roomPlan.squareMeter">
      <a-input v-model:value="form.roomPlan.squareMeter" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Remarks regarding the bed')"
      name="roomPlan.bedRemarks"
    >
      <a-input v-model:value="form.roomPlan.bedRemarks" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Gateway Location')"
      name="roomPlan.gatewayLocation"
    >
      <a-input v-model:value="form.roomPlan.gatewayLocation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Other remarks')" name="roomPlan.otherRemarks">
      <a-input v-model:value="form.roomPlan.otherRemarks" :size="size" type="text" />
    </a-form-item>

    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        Save
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Copy From": "Copy From",
    "Room Code": "Room Code",
    "Room Name": "Room Name",
    "Room Type": "Room Type",
    "Facilities": "Facilities",
    "Facility": "Facility",
    "Capacity": "Capacity",
    "Total Beds": "Total Beds",
    "Single Beds": "Single Beds",
    "Semi-Double Beds": "Semi-Double Beds",
    "Double Beds": "Double Beds",
    "Futons": "Futons",
    "Bedrooms": "Bedrooms",
    "Room Plan": "Room Plan",
    "Room Plan Diagram": "Room Plan Diagram",
    "Browse File": "Browse File",
    "Breaker location": "Breaker location",
    "Gas Meter Type Number": "Gas Meter Type Number",
    "Gas Meter Location": "Gas Meter Location",
    "IH Stove Type": "IH Stove Type",
    "Wifi Router Location": "Wifi Router Location",
    "Wifi Accesspoint Location": "Wifi Accesspoint Location",
    "Wifi Key 1": "Wifi Key 1",
    "Wifi Password 1": "Wifi Password 1",
    "Wifi Key 2": "Wifi Key 2",
    "Wifi Password 2": "Wifi Password 2",
    "Boiler Control Panel Location": "Boiler Control Panel Location",
    "Square Meter": "Square Meter",
    "Remarks regarding the bed": "Remarks regarding the bed",
    "Gateway Location": "Gateway Location",
    "Other remarks": "Other remarks",
    "Rakuten Travel ID": "Rakuten Travel ID",
    "Save": "Save",
    "Anshin Room": "Anshin Room",
    "Add": "Add",
    "Done": "Done",
    "Room code is required": "Room code is required",
    "Room type is required": "Room type is required",
    "Room name is required": "Room name is required",
    "Capacity is required": "Capacity is required",
    "Total beds is required": "Total beds is required",
    "Bedrooms is required": "Bedrooms is required",
    "Single beds is required": "Single beds is required",
    "Double beds is required": "Double beds is required",
    "Semi double beds is required": "Semi double beds is required",
    "Futons is required": "Futons is required",
    "Anshin room is required": "Anshin room is required",
    "Room number is required": "Room number is required",
  },
  "ja": {
    "Copy From": "コピー",
    "Room Code": "部屋コード",
    "Room Name": "部屋番号",
    "Room Type": "部屋タイプ",
    "Facilities": "設備",
    "Facility": "設備",
    "Capacity": "収容人数",
    "Total Beds": "総Bed数",
    "Single Beds": "Single Bed数",
    "Semi-Double Beds": "Semi-Double Bed数",
    "Double Beds": "Double Bed数",
    "Futons": "布団Set数",
    "Bedrooms": "ベッドルーム数（独立した寝室数）",
    "Room Plan": "運用情報",
    "Room Plan Diagram": "客室平面図",
    "Browse File": "閲覧ファイル",
    "Breaker location": "ブレーカー位置",
    "Gas Meter Type Number": "マイコン型番",
    "Gas Meter Location": "マイコン位置",
    "IH Stove Type": "IHコンロ型番",
    "Wifi Router Location": "Wifiルーター設置場所",
    "Wifi Accesspoint Location": "WIFI中継機設置",
    "Wifi Key 1": "Wifi①名",
    "Wifi Password 1": "Wifi①暗証番号",
    "Wifi Key 2": "Wifi②名",
    "Wifi Password 2": "Wifi②暗証番号",
    "Boiler Control Panel Location": "給湯器パネル場所",
    "Square Meter": "平米数",
    "Remarks regarding the bed": "Bedに関する備考",
    "Gateway Location": "ゲートウェイ設置場所",
    "Other remarks": "その他特記事項",
    "Rakuten Travel ID": "Rakuten Travel ID",
    "Save": "保存",
    "Anshin Room": "あんしん部屋",
    "Add": "追加",
    "Done": "終わり",
    "Room code is required": "部屋コードが必要です",
    "Room type is required": "部屋タイプが必要です",
    "Room name is required": "部屋名が必要です",
    "Capacity is required": "収容人数が必要です",
    "Total beds is required": "総Bed数",
    "Bedrooms is required": "ベッドルーム数（独立した寝室数が必要です",
    "Single beds is required": "Single Bed数が必要です",
    "Double beds is required": "Double Bed数が必要です",
    "Semi double beds is required": "Semi-Double Bed数が必要です",
    "Futons is required": "布団Set数が必要です",
    "Anshin room is required": "あんしん部屋が必要です",
    "Room number is required": "Room number is required",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import { getToken } from '@/utils/auth';

import Modal from '@/components/Modal';
import InputRoomFromExisting from '@/components/InputRoom';
import InputFacility from '@/components/InputFacilityGroup';
import InputRoomType from '@/components/InputRoomType';

export default {
  name: 'RoomForm',
  components: {
    InputFacility,
    InputRoomFromExisting,
    Modal,
    InputRoomType,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    facilityList: {
      type: Array,
      default: () => [],
    },
    anshinRooms: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submit'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      apiHeaders: {
        authorization: `Bearer ${getToken()}`,
      },
      copyOfId: undefined,
      form: {
        anshinRoomId: undefined,
        code: undefined,
        name: undefined,
        type: undefined,
        capacity: undefined,
        totalBeds: undefined,
        singleBeds: undefined,
        semiDoubleBeds: undefined,
        doubleBeds: undefined,
        futons: undefined,
        bedrooms: undefined,
        roomPlan: {
          diagram: undefined,
          breakerlocation: undefined,
          gasMeterTypeNumber: undefined,
          gasMeterLocation: undefined,
          stoveType: undefined,
          wifiRouterLocation: undefined,
          wifiAccesspointLocation: undefined,
          wifiKey1: undefined,
          wifiPassword1: undefined,
          wifiKey2: undefined,
          wifiPassword2: undefined,
          boilerControlPanelLocation: undefined,
          tablet: undefined,
          tabletMode: undefined,
          tabletLocation: undefined,
          squareMeter: undefined,
          bedRemarks: undefined,
          gatewayLocation: undefined,
          otherRemarks: undefined,
        },
        facilities: [],
        roomNumber: undefined,
        roomTypeId: undefined,
        rtRoomId: [],
      },
      rules: {
        code: {
          required: true,
          message: this.$t('Room code is required'),
          trigger: ['change', 'blur'],
        },
        name: {
          required: true,
          message: this.$t('Room name is required'),
          trigger: ['change', 'blur'],
        },
        capacity: {
          required: true,
          type: 'number',
          message: this.$t('Capacity is required'),
          trigger: ['change', 'blur'],
        },
        totalBeds: {
          required: true,
          type: 'number',
          message: this.$t('Total beds is required'),
          trigger: ['change', 'blur'],
        },
        bedrooms: {
          required: true,
          type: 'number',
          message: this.$t('Bedrooms is required'),
          trigger: ['change', 'blur'],
        },
        singleBeds: {
          required: true,
          type: 'number',
          message: this.$t('Single beds is required'),
          trigger: ['change', 'blur'],
        },
        semiDoubleBeds: {
          required: true,
          type: 'number',
          message: this.$t('Semi double beds is required'),
          trigger: ['change', 'blur'],
        },
        doubleBeds: {
          required: true,
          type: 'number',
          message: this.$t('Double beds is required'),
          trigger: ['change', 'blur'],
        },
        futons: {
          required: true,
          type: 'number',
          message: this.$t('Futons is required'),
          trigger: ['change', 'blur'],
        },
      },
      size: 'large',
      fileList: [],
      uploadUrl: `${process.env.VUE_APP_API_URL}/rooms/diagram`,
    };
  },
  computed: {
    ...mapState('properties', ['property']),
    modifiedRules() {
      const rules = { ...this.rules };
      if (this.property.useRoomType) {
        rules.roomTypeId = [
          {
            required: true,
            type: 'number',
            message: this.$t('Room type is required'),
            trigger: ['change', 'blur'],
          },
        ];

        rules.roomNumber = [
          {
            required: true,
            type: 'number',
            message: this.$t('Room number is required'),
            transform: (value) => (Number(value) > 0 ? Number(value) : null),
            trigger: ['change', 'blur'],
          },
        ];
      }

      return rules;
    },
  },
  created() {
    if (this.property.anshinPropertyId) {
      this.rules = {
        ...this.rules,
        anshinRoomId: {
          required: true,
          message: this.$t('Anshin room is required'),
          trigger: ['change', 'blur'],
        },
      };
    }
  },
  methods: {
    handleImageChange({ file }) {
      if (file.status === 'done') {
        this.form.roomPlan.diagram = file.response.created.path;
        this.fileList.push(file);
      }
    },
    filterFacilities(val) {
      return this.facilityList.filter((f) => f.name.toLowerCase().indexOf(val.toLowerCase) > -1);
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.$emit('submit', { ...this.form, facilities: this.form.facilities.map((f) => f.id) });
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.copyOfId = undefined;
      this.$refs.form.resetFields();
    },
    async handleRoomCopy(roomId) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      const data = await this.$store.dispatch('rooms/get', { id: roomId });

      const cloneRoom = { ...data };
      delete cloneRoom.createdAt;
      delete cloneRoom.updatedAt;
      delete cloneRoom.id;
      this.form = {
        ...cloneRoom,
        facilities: cloneRoom.facilities,
      };

      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    removeFacility(id) {
      this.form.facilities = this.form.facilities.filter((f) => f.id !== id);
    },
    handleRoomTypeChange(id, data) {
      this.form.roomTypeId = id;
      this.form.type = data.name;
    },
  },
};
</script>
