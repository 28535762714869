<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-primary has-text-weight-bold is-size-4">
        {{ roomType ? $t('Edit Room Type') : $t('Add Room Type') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Room Type Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item
      class="column is-6"
      :label="$t('Neppan Room Type ID')"
      name="neppanRoomType"
    >
      <a-select v-model:value="form.neppanRoomType" :size="size">
        <a-select-option
          v-for="item in neppanRoomTypes"
          :key="item.roomTypeGroupCode"
          :value="item.roomTypeGroupCode"
        >
          {{ item.roomTypeGroupName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      class="column is-6"
      :label="$t('Status')"
      name="isActive"
    >
      <a-select v-model:value="form.isActive" :size="size">
        <a-select-option
          v-for="item in statuses"
          :key="item.label"
          :value="item.value"
        >
          {{ $t(item.label) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <div class="column is-12">
      <div class="section-title">
        {{ $t('Room with this type') }}
      </div>
      <div
        v-for="(_, idx) in form.roomIds"
        :key="idx"
        class="m-b-3x is-flex is-align-items-center"
      >
        <a-select
          v-model:value="form.roomIds[idx]"
          :size="size"
          class="m-r-3x"
        >
          <a-select-option
            v-for="item in rooms"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <CloseOutlined
          class="has-text-danger has-text-weight-bold"
          @click="removeRoom(idx)"
        />
      </div>
      <a-button
        :size="size"
        class="button-secondary-outline"
        style="min-width: 115px"
        @click="form.roomIds.push(undefined)"
      >
        {{ $t('Add Room') }}
      </a-button>
    </div>
    <div class="column is-4 is-mini-gap m-t-4x">
      <a-button
        type="danger"
        style="min-width: 125px"
        :loading="loading"
        :size="size"
        @click="handleDel"
      >
        {{ $t('delete') }}
      </a-button>
    </div>
    <div class="column is-mini-gap has-text-right m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { mapState } from 'vuex';
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
  name: 'RoomTypeForm',
  components: {
    CloseOutlined,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    roomType: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['submit', 'cancel', 'delete'],
  data() {
    return {
      loading: false,
      neppanRoomTypes: [],
      selectedRoom: [],
      statuses: [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' },
      ],
      removedRooms: [],
      form: {
        id: undefined,
        name: undefined,
        neppanRoomType: undefined,
        isActive: undefined,
        roomIds: [],
      },
      rooms: [],
      size: 'large',
      rules: {
        name: {
          required: true,
          message: this.$t('Room type name is required'),
          trigger: ['change', 'blur'],
        },
        isActive: {
          type: 'boolean',
          required: true,
          message: this.$t('Status is required'),
          trigger: ['change', 'blur'],
        },
      },
    };
  },
  computed: {
    ...mapState('properties', ['property', 'loading']),
  },
  watch: {
    roomType: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.form.id = nv.id;
          this.form.name = nv.name;
          this.form.isActive = nv.isActive;
          this.form.neppanRoomType = nv.neppanRoomType;
          if (nv.rooms.length) {
            this.form.roomIds = nv.rooms.map((r) => r.id);
          }
        }
      },
    },
  },
  async created() {
    await this.fetchRooms();
    await this.fetchNeppanRoomTypes();
  },
  methods: {
    fetchRooms() {
      const params = {
        propertyId: this.property.newPropertyCode,
        infinite: true,
      };
      return this.$store.dispatch('rooms/names', params).then((res) => {
        this.rooms = res.list;

        return Promise.resolve();
      });
    },
    fetchNeppanRoomTypes() {
      const params = {
        propertyId: this.property.newPropertyCode,
      };
      return this.$store.dispatch('rooms/listNeppanRoomTypes', params).then((res) => {
        this.neppanRoomTypes = res.rooms || [];

        return Promise.resolve();
      });
    },
    async handleSubmit() {
      await this.$refs.form.validateFields();
      this.$emit('submit', { ...this.form, propertyId: this.property.newPropertyCode });
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    handleDel() {
      this.$emit('delete', this.form.id);
    },
    removeRoom(idx) {
      this.form.roomIds.splice(idx, 1);
    },
  },
};
</script>

<style lang="scss">
.section-title {
  background-color: #003945;
  padding: .5rem;
  color: #fff;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: 700;
}
</style>
